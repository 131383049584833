<template>
    <div class="chapter">
       <Header/>
        <div class="body">
         <div class="nav">
             <el-breadcrumb separator-class="el-icon-arrow-right">
                 <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                 <el-breadcrumb-item>章节练习</el-breadcrumb-item>
             </el-breadcrumb>

              <CategoryTitle title="章节练习" desc="想要练习其他科目"/>
         </div>
            <empty height="400px" v-if="empty"></empty>
            <div class="chapter-list">

                <div :class="item.complete===item.uncomplete?'item finished':'item'" v-for="(item,index) in chapterList" :key="index">
                    <div class="title">第{{(index+1)}}章 {{item.categoryName}}</div>
                    <div class="progress progress-text">
                        <div>{{item.complete}}/{{item.uncomplete}}</div>
                        <div style="margin-left: 5px">{{item.result}}</div>
                    </div>

                    <div class="desc">{{item.describe||''}}</div>

                      <div class="btn" @click="jump(item)">去做题</div>

                </div>
            </div>
            <loading :rows="6" v-if="loading"></loading>

        </div>

        <Footer/>
    </div>
</template>

<script>
    import Header from "@/components/Header.vue"
    import CategoryTitle  from  '@/components/CategoryTitle'
    import Footer from "@/components/Footer.vue"
    import Empty from '@/components/Empty'
    import Loading from '@/components/Loading'
    import {GetChapterList} from '@/api/list'
    export default {
        name: "Chapter",
        components:{
            Header,
            CategoryTitle,
            Empty,
            Loading,
            Footer
        },

        data(){

            return{
                chapterList:[],
                loading:true,
                empty:false
            }
        },

        created(){

            this.getChapterData();

            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.getChapterData()

            })

        },

        methods:{

            //加载章节数据
            getChapterData(){
                this.loading=true;
                this.chapterList=[];
                this.empty=false;
                GetChapterList(this.$store.state.user.category.id).then(res=>{

                    this.chapterList = res.data;
                    this.empty = this.chapterList.length===0
                }).finally(()=>{
                    this.loading=false;
                })

            },
            //跳转
            jump(item){
                if(item.uncomplete===0){

                    this.$message.error('本章暂无题目')
                    return
                }
                this.$router.push({path:'/chapterPractice',query:{chapterId:item.id,name:item.categoryName}})
            },
        },

        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }


    }
</script>

<style lang="less" scoped>

    .chapter{
        background-color: #f2f2f2;
        overflow: hidden;
    }
    .body{

        width: 1200px;
        min-height: 600px;
        margin: 20px auto;

        .chapter-list{
            margin-top: 30px;
            display: grid;
            grid-template-columns: repeat(5,1fr);
            gap: 10px;

            .item{
                background-color: #fff;
                border-radius: 10px;
                min-height: 200px;
                padding: 20px;
                cursor: pointer;
                position: relative;
                border: 2px solid #FFFFFF;

                &:hover{

                    background: linear-gradient(134deg, #D9E8FF 0%, #F7FCFF 100%);
                    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.05);
                    border-radius: 10px;
                    border: 2px solid #FFFFFF;

                    .btn{
                        display: block;
                    }

                }

                .title{
                    font-size: 20px;
                    color: #3B89FF;
                }
                .progress{
                    font-size: 15px;
                    color: rgba(60,60,67,0.6);
                    display: flex;
                }
                .desc{
                    font-size: 20px;
                    font-weight: bold;
                }

                .btn{
                    height: 40px;
                    width: 184px;
                    line-height: 40px;
                    color: #fff;
                    display: none;
                    text-align: center;
                    background: linear-gradient(360deg, #48C2FF 0%, #3B89FF 100%);
                    box-shadow: 0 3px 3px 1px rgba(108,191,249,0.2);
                    border-radius: 24px;
                    position: absolute;
                    bottom: 15px;
                }

            }
            .finished{

                border: 1px solid #5BC65C;

                .progress-text{
                    color:#5BC65C;
                }

            }
        }
    }

</style>
