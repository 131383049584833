<template>
    <div class="categoryTitle">
        <div class="title">{{title}}</div>
        <div class="desc">{{desc}}？点这里<el-popover placement="bottom" width="400" trigger="hover" v-model="visible">
            <div style="padding: 10px">
                <Category @onClose="visible=false"/>
            </div>

            <el-button type="text" slot="reference" size="small">切换科目</el-button>
            </el-popover>
        </div>

    </div>
</template>

<script>
    import Category from '@/components/Category'
    export default {
        name: "CategoryTitle",
        props:{

            title:{
                type:String,
                default:'',
                required:true
            },
            desc:{
                type:String,
                default:'',

            }

        },
        data(){
            return{
                visible:false
            }
        },
        components:{
            Category
        }
    }
</script>

<style lang="less" scoped>

    .categoryTitle{
        .title{
            margin-top: 24px;
            font-size: 34px;

        }
        .desc{
            color: rgba(60, 60, 67, 0.60);
            font-size: 14px;
        }
    }


</style>
