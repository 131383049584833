<template>
    <div class="my-loading">
        <el-skeleton :rows="rows"  animated/>
    </div>
</template>

<script>
    export default {
        name: "Loading",
        props:{
            rows:{
                type:Number,
                default:4
            }
        }
    }
</script>

<style scoped>

    .my-loading{
        width: 100%;
        background-color: #fff;
        padding: 10px;
    }

</style>
